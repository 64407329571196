import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text,Well } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import '../components/cssfile/header.css';
import '../components/cssfile/home.css';
import '../components/cssfile/footer.css';
import '../components/cssfile/admin.css';
import '../components/cssfile/card.css';
import '../components/cssfile/ca.css';
import '../components/cssfile/sidebar.css';
import '../components/cssfile/dashboard.css';

class Instruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            instruction: [],
            language: '', // new state variable for selected language
        }
        this.handleLanguageChange = this.handleLanguageChange.bind(this); // bind the handler function to this component
        this.handleNext = this.handleNext.bind(this); // bind the handler function to this component
    }

    handleLanguageChange(event) { // handler function to update selected language
        this.setState({ language: event.target.value });
    }

    handleNext() { // handler function for "Next" button
        // add your code here to handle navigation to the next page
    }

    componentDidMount() {
        const test_id = localStorage.getItem('test_id');
        const param4 = test_id;
        axios.get(`https://erwatoday.com/serverside/instruction.php?test_id=${param4}`)
            .then(response => {
                console.log(response.data);
                this.setState({ instruction: response.data, isLoading: false });
            })
            .catch(error => {
                console.log(error)
            })
    }


    render() {

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const regid = localStorage.getItem('regid');
        const test_id = localStorage.getItem('test_id');

        const exam_id1 = this.props.location.state.id;
        const exam_id = localStorage.setItem('exam_id', exam_id1);
        // alert(regid);
        if (!email || !regid) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }

        return (
            <div>
            <div className='well'  style={{ boxShadow: "1px 0px 4px #9E9E9E", color: "black",height:'45px' }}>
                <h3 style={{color:' #118585',paddingLeft:'20px'}}>ErwaToday.com</h3>
            </div>
            <div className='container-fluid' >
            
                <div className='row' style={{ overflowY: 'scroll', height: '580px', userSelect: 'none', overflow: 'hidden' }}>
                    <div className='col-sm-8' style={{ overflowY: 'scroll', height: '635px', userSelect: 'none' }} >

                        {this.state.instruction.map((item) => (
                            <div>
                                <span style={{ float: 'right' }} >Default Language:
                                    <select value={this.state.language} onChange={this.handleLanguageChange}>
                                        <option value="">...select...</option>
                                        <option value="hi">Hindi</option>
                                        <option value="en">English</option>
                                    </select>
                                </span>
                                <h5 id="h3">{this.props.location.state.topic_name} | {item.topic_name}</h5>
                                <div>{ReactHtmlParser(item.des)}</div>
                                {!this.state.language &&
                                    <div id="myseries_card" style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'center' }}>
                                        <h4 id="h3">First Select Language,Before Starting Test !</h4>
                                    </div>
                                }

                                {this.state.language &&
                                    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '', textAlign: 'center' }}>
                                        <Link to={{ pathname: `/edashboard`, state: { exam_id: exam_id1, test_id: test_id, regid: regid, email: email, topic_name: item.topic_name } }}>
                                            <button className='btn btn-primary' style={{ float: 'right', width: '100%',background:'#118585' }}>Start Test Now</button>
                                        </Link>

                                    </div>
                                }
                            </div>
                        ))}



                    </div>


                    <div className='col-sm-3' id="myseries_card" style={{ marginLeft: '20px', marginRight: '0', height: '400px' }}>
                        <div style={{ textAlign: 'center', marginTop: '30PX' }} >
                            <FaUserCircle style={{ fontSize: '80px' }} />
                            <h6 id="h3">{email}</h6>
                            <p>ID:{regid}</p>
                        </div>
                    </div>

                </div>
            </div>
            </div>

        );
    }
}

export default Instruction;