import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import loginimage from './img/erwatodaye.jpg';
import { Container, Row, FormGroup, FormLabel, FormControl, } from 'react-bootstrap';
class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignIn: true, // whether the user is signing in or signing up
      email: '',
      password: '',
      phone: '',
      error: ''
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }



  handleSignInSubmit = (event) => {
    event.preventDefault();
    var dat = {
      email: this.state.email,
      password: this.state.password,
    }

    axios.post('https://erwatoday.com/serverside/login.php', dat)
      .then(response => {
        console.log(response);
        if (response.data === 'success') {
          const { email, password,regid } = this.state;
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);
          localStorage.setItem('regid', response.data.regid);
          this.props.history.push('/dashboard', { email, password });
        } else {
          this.setState({ error: response.data.error });
          alert("Student email id or Password is not matched!!");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  handleSignUpSubmit = (event) => {
    event.preventDefault();
    console.log(this.state)
    axios.post('https://erwatoday.com/serverside/user.php', this.state)
      .then(response => {
        console.log(response)
        alert(response.data);
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.log(error)
        alert(error);
      })
  }

  toggleSignIn = () => {
    this.setState({
      isSignIn: true,
      email: '',
      password: '',
      phone: '',
    });
  }

  toggleSignUp = () => {
    this.setState({
      isSignIn: false,
      email: '',
      password: '',
      phone: '',
    });
  }

  render() {
    const { isSignIn, email, password, phone } = this.state;

    return (
      <div className='container' style={{marginTop:'80px',}}>

        <div className='row'>
          <div className='col-sm-5'>
      <img src={loginimage} style={{height:'400px',width:'300px',borderRadius:'10px'}}/>
          </div>
          <div className='col-sm-6' id="panel" style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '20px' }}>
  <div className="auth-form" style={{ fontSize: '18px' }}>
    <h2 id="h3" style={{ marginBottom: '20px' }}>{isSignIn ? 'Sign In' : 'Sign Up'}</h2>
    <form onSubmit={isSignIn ? this.handleSignInSubmit : this.handleSignUpSubmit}>
      <FormGroup>
        <FormLabel htmlFor="email" style={{ marginBottom: '5px' }}>Email</FormLabel>
        <FormControl type="email" id="email" name="email" value={email} onChange={this.handleChange} required />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="password" style={{ marginBottom: '5px' }}>Password</FormLabel>
        <FormControl type="password" id="password" name="password" value={password} onChange={this.handleChange} required />
      </FormGroup>
      {!isSignIn && (
        <React.Fragment>
          <FormGroup>
            <FormLabel htmlFor="phone" style={{ marginBottom: '5px' }}>Phone Number</FormLabel>
            <FormControl type="text" id="phone" name="phone" value={phone} onChange={this.handleChange} required />
          </FormGroup>
        </React.Fragment>
      )}
      <br></br>
      <button type="submit" className='btn btn-primary' style={{ backgroundColor: '#007bff', border: 'none' }}>{isSignIn ? 'Sign In' : 'Sign Up'}</button>
    </form>
    <p>{isSignIn ? "Don't have an account?" : 'Already have an account?'} <a href="#" onClick={isSignIn ? this.toggleSignUp : this.toggleSignIn}>{isSignIn ? 'Sign up' : 'Sign in'}</a></p>
  </div>
</div>

          

        </div>
      </div>
    );
  }
}

export default HOC(AuthForm);
