import React from 'react';
import axios from 'axios';


import { Container, Row, FormGroup, FormLabel, FormControl, } from 'react-bootstrap';

class Signup extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            phone: '',
            password: '',
            confpassword: '',
           pic:null
        }
    }

    //  this is part for accessing the value from inputbox
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            console.log(this.state)
        })
    }

    // Accessing the value by API from Url
    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state)
        axios.post('http://localhost:4000/api/admin/add', this.state)
          .then(response => {
              console.log(response)
              alert("Successfulyy data inserted");
          })
          .catch(error => {
              console.log(error)
              alert("Faild your Submition")
          }) 
        }


    render() {
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <div className="container" id="panel">
                            <div className=''>
                                <h3 id="h3">Admin Signup</h3>
                            </div><hr id="hr" />
                            <form onSubmit={this.handleSubmit} method="POST" encType="multipart/form-data">
                                <FormGroup>
                                    <FormLabel>Enter Your Name:<span id="star">*</span></FormLabel>
                                    <FormControl type="text" size="sm" name="name" placeholder="Enter name" onChange={this.handleChange}></FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Email Id:<span id="star">*</span></FormLabel>
                                    <FormControl type="email" size="sm" name="email" placeholder="xyz@gmail.com" onChange={this.handleChange}></FormControl>
                                    {this.emailerror}
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Phone/Mobile No.:<span id="star">*</span></FormLabel>
                                    <FormControl type="text" size="sm" name="phone" placeholder="Enter phone" onChange={this.handleChange}></FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Enter password:<span id="star">*</span></FormLabel>
                                    <FormControl type="password" size="sm" name="password" placeholder="password" onChange={this.handleChange}></FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Enter Conformpassword:<span id="star">*</span></FormLabel>
                                    <FormControl type="text" size="sm" name="confpassword" placeholder="Enter conpassword" onChange={this.handleChange}></FormControl>
                                </FormGroup>
                                
                                <FormGroup>
                                    <FormLabel>Upload Image :<span id="star">*</span></FormLabel>
                                    <FormControl type="file" size="sm" name="pic"  onChange={this.handleChange}></FormControl>
                                    
                                </FormGroup>
                                <input type="submit" class="btn btn-info" value="Submit"  />
                            </form>
                        </div>

                    </Row>
                </Container>
            </React.Fragment>
        );

    }
}

export default Signup; 