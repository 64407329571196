import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';

class PrivacyPolicy extends React.Component {
render() {
return (

<div id="privacypolicy">
<Header />
<Navbar expand="lg">
<Container>
<Row>
<Col lg={12}>
<h1>Privacy Policy</h1>
<hr />
<p>At ErwaToday.com, we are committed to protecting your privacy. This privacy policy explains how we collect, use, and disclose your personal information.</p>
</Col>
</Row>
</Container>
</Navbar>
<div className="container ">
<div className="row justify-content-center">
<div className="col-md-12">
<h3>Information We Collect</h3>
<p>We collect information from you when you register on our site or fill out a form. This may include your name, email address, phone number, and other personal information.</p>
<p>We may also collect non-personal information about your visit to our site, such as the pages you viewed, the links you clicked, and the search terms you used. This information is used to improve our site and provide better services to our users.</p>
<h3>How We Use Your Information</h3>
<p>We use your personal information to provide you with the services you requested, to communicate with you, and to improve our site and services. We may also use your information to send you promotional materials, such as newsletters or special offers, unless you opt out of receiving these communications.</p>
<p>We do not sell or rent your personal information to third parties. However, we may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and marketing. These service providers are not authorized to use your information for any purpose other than to provide the services we have requested.</p>
<h3>How We Protect Your Information</h3>
<p>We use a variety of security measures to protect your personal information, including encryption, firewalls, and secure server technology. We also require our employees and service providers to follow strict guidelines when handling your information.</p>
<h3>Cookies</h3>
<p>We use cookies to improve your experience on our site and to provide you with personalized content and ads. You can choose to accept or reject cookies by changing your browser settings.</p>
<h3>Online Payment Transactions</h3>
<p>We understand the importance of privacy and security when it comes to online payment transactions. We use third-party payment processors to process all payments made through our site, and we do not store or have access to your payment information.</p>
<p>When you make a payment through our site, you will be directed to the payment processor's site, where you will be required to provide your payment information. The payment processor's privacy policy and terms of use will apply to your use of their site and services. We recommend that you review their policies before making a payment.</p>
<h3>Changes to This Policy</h3>
<p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
<h3>Contact Us</h3>
<p>If you have any questions or concerns about this privacy policy, please contact us at privacy@erwatoday.in.</p>
</div>
</div>
</div>
<Footer />
</div>
);
}
}
export default PrivacyPolicy;