import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';

import Footer from './footer';
import Header from './header';

class AboutUs extends React.Component {
  render() {
    return (
      <div id="aboutus">
       <Header/>
        <Navbar  expand="lg">
          <Container>
            <Row>
              <Col lg={12}>
              <h1>About Us</h1>
<hr />
<p>ErwaToday.com is a platform that provides online mock test series for government exams. Our goal is to help students and job seekers prepare for various government exams in an efficient and effective manner. We offer a wide range of mock tests for exams such as SSC, UPSC, IBPS, and more.</p>
<p>Our mock tests are designed by experts in the field and are based on the latest exam patterns and syllabus. We also provide detailed performance analysis and feedback to help students identify their strengths and weaknesses and improve their scores.</p>
<p>At ErwaToday.com, we believe in providing quality education at an affordable price. We strive to make our platform accessible to students from all backgrounds and ensure that they have the best possible resources to succeed in their exams.</p>
<p>In addition to mock tests, we also offer study materials, current affairs updates, and other resources to help students stay up-to-date and informed. Our platform is designed to be user-friendly and easy to navigate, so students can focus on learning and preparing for their exams.</p>
<p>Whether you are a student or a job seeker, ErwaToday.in is here to help you achieve your goals. Join our platform today and start preparing for your dream job.</p>
<p>If you have any questions or feedback, please do not hesitate to contact us.</p>
              </Col>
            </Row>
          </Container>
        </Navbar>
        <Footer></Footer>
      </div>
    );
  }
}

export default AboutUs;
