import React from 'react';
import axios from 'axios';
import Header from './header';
import Footer from './footer';
import { FaAngular, FaListAlt,FaSignOutAlt,FaBars,FaHome,FaBook,FaRegHeart,FaCartArrowDown,FaSearch, FaHeart } from "react-icons/fa";
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class Mockheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: false,
      isLoggedIn: localStorage.getItem('email') && localStorage.getItem('password')
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  handleLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('regid');
    window.location.reload();
  }

  render() {

    


    const { isLoggedIn } = this.state;
    return (
      <Container fluid style={{marginTop:'0px'}}>
        <Row>
          <Col sm={9} className="d-block d-sm-none">
            <Button onClick={this.toggleSidebar}><FaBars/></Button>
          </Col>
          <Col sm={3} className={this.state.showSidebar ? "d-block" : "d-none d-sm-block"}>
            <div className="sidebar-content" style={{ overflowY: 'scroll', height: '560px' }}>
              <ul>
                <li><Link style={{color:'white'}}  to={{pathname:'/dashboard'}}><FaHome/> Home</Link></li> 
                <li><Link style={{color:'white'}}  to={{pathname:'/home'}}><FaBook/> Add Series</Link></li> 
                <li><Link style={{color:'white'}}  to={{pathname:''}}><FaHeart/>  Live Test</Link></li>
                <li><FaCartArrowDown/>  Current Affairs</li>
                <li><FaSearch/>  Practice </li>
                <li>
                  {isLoggedIn 
                    ? <button className='btn btn-info' onClick={this.handleLogout}><FaSignOutAlt />Logout</button>
                    : <Link to={{pathname:'/login'}} className='btn btn-info'>Sign In</Link>
                  }
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
