import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Card,Title,Text } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Footer from './footer';
import Header from './header';

import himage from './img/homeimage.jpg';

import axios from 'axios';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      addcategory: [],
      category:'',
      message:[],

    }
  }

  componentDidMount() {


    axios
    .get(`https://erwatoday.com/serverside/message.php`)
    .then(response => {
      this.setState({ message: response.data });
    })
    .catch(error => {
      console.log(error);
    });


    axios.get('https://erwatoday.com/serverside/parentcategory.php')
      .then(response => {
        console.log(response)
        this.setState({ addcategory: response.data })
      })
      .catch(error => {
        console.log(error)
      })
  }


  addSeries = (event, category) => {
    event.preventDefault();
    const regid = localStorage.getItem('regid'); 
  
    // if regid is not found, redirect to login page
    if (!regid) {
      window.location.href = '/login';
      return; // stop further execution of the function
    }
  
    this.setState({ category }); // update the category state variable
    axios.post('https://erwatoday.com/serverside/addseries.php', { category, regid })
      .then(response => {
        // alert(response.data);
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.log(error)
        alert(error);
      })
  }
  
  

  render() {
    
    return (

      <div>
      <Header/>
        <div className='container'>
        <div>
            <Slider dots={true} infinite={true} speed={500} slidesToShow={3} autoplay={true} autoplaySpeed={2000}>
              {this.state.message.map((item) => (
                <div id="panel" style={{ marginLeft: '5px',height:'300px',padding:'5px' }}>
                  <p>{item.name}</p>
                  <div>{ReactHtmlParser(item.des)}</div>
                </div>
              ))}
            </Slider>
          </div>
        <h3 id="h3">Online mock Test Series</h3>
        <div>
        <div>
          <img src={himage} style={{height:'300px',width:'450px'}}/>
        </div>
        
        Online mock test series have become an essential tool for candidates preparing for various government exams. 
        These mock tests are designed to provide candidates with an opportunity to practice and assess their preparation 
        for the actual exam. With the advancement of technology, online mock test series have become more accessible and
         convenient for candidates to take from the comfort of their homes.
         
         </div>
        {/* <h1 id="h1"> Current Affairs 2023 </h1> */}
          <div className='row' >
            {this.state.addcategory.map((item) => (
              <div className='col-sm-3'>
                <div  id="card">
                <img src={item.image} width="50px" height="50px" id="dashboard_img"  />
                <br></br>
                <br></br>
                    <p>{item.topic_name}</p>
                    <hr></hr>
                    <p id="cardp">1.{item.total_set}<br></br>
                    2.{item.previous_year}</p>
                    <p>And many more....</p>
                    <button id="button" className='btn btn-outline-info' onClick={(e) => this.addSeries(e, item.category)}>Get Started Now</button>
                </div>
              </div>
            ))}

          </div>

        </div>
        <Footer></Footer>
      </div>
    );

  }
}

export default Home; 