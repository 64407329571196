import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

class Footer extends React.Component {
  render() {
    return (
      <div id="footer" style={{ backgroundColor: 'rgb(35, 85, 92)' }}>
        <Navbar  expand="lg">
          <Container>
            <Row>
              <Col lg={4} className="mb-3 mb-lg-0" style={{color:'white'}}>
                <Navbar.Brand style={{color:'white'}}>
                <h5>ErwaToday.com</h5>
                </Navbar.Brand>
                <p>B.L SAHA ROAD TOLLYGUNJ, KOLKATA WEST BENGAL -700053</p>
                <p>9608372362</p>
                <p>administrative@erwatoday.com</p>
                <p>developer@erwatoday.com</p>
              </Col>
              <Col lg={4} className="mb-3 mb-lg-0" style={{color:'white'}}>
                <h5>Connect with Us</h5>
                <a href="https://www.facebook.com/skhackergh/" style={{ color: 'white' }}><FaFacebook /> Facebook</a><br />
                <a href="https://twitter.com/sikanda47058982" style={{ color: 'white' }}><FaTwitter /> Twitter</a><br />
                <a href="https://www.instagram.com/_____sikandar/" style={{ color: 'white' }}><FaInstagram /> Instagram</a>
              </Col>
              <Col lg={4} className="mb-3 mb-lg-0" style={{color:'white'}}>
                <h5>Company</h5>
                <a href="/aboutus" style={{ color: 'white' }}>About Us</a><br />
                <a href="/contactus" style={{ color: 'white' }}>Contact Us</a><br />
                <a href="/privacy" style={{ color: 'white' }}>Privacy Policy</a><br />
                <a href="/termscondition" style={{ color: 'white' }}>Terms and Conditions</a>
                <a href="/paymentc" style={{ color: 'white' }}>Payment terms /Refund and Cancellation Policy</a>
              </Col>
            </Row>
            <hr style={{ backgroundColor: 'white' }}></hr>
            <p className="text-center" style={{ color: 'white' }}>&copy; 2023 ErwaToday.com. All Rights Reserved.</p>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Footer;
