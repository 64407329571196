import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaAward, FaTrophy, FaFileAlt, FaUsers, FaUserCircle } from "react-icons/fa";
import { FcStatistics } from "react-icons/fc";

import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';

import '../components/cssfile/result.css';

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: [],
            rank: [],
            toper: [],
            regid: '',

        }
    }

    componentDidMount() {


        localStorage.removeItem('eaxm_id');
   
        // const regid = this.props.location.state.regid;
        // const exam_id = this.props.location.state.id;
        // console.log(regid2+exam_id2)

        const regid1 = this.props.location.state.regid || this.props.location.state.data.regid;
        const exam_id1 = this.props.location.state.id || this.props.location.state.data.exam_id;

        axios
            .get(`https://erwatoday.com/serverside/analysis.php?exam_id=${exam_id1}&regid=${regid1}`)
            .then((response) => {
                // console.log(response.data);
                this.setState({ analysis: response.data });
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(`https://erwatoday.com/serverside/rank.php?exam_id=${exam_id1}&regid=${regid1}`)
            .then((response) => {
                // console.log(response.data);
                this.setState({ rank: response.data });
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(`https://erwatoday.com/serverside/top_rank.php?exam_id=${exam_id1}&regid=${regid1}`)
            .then((response) => {
                console.log(response.data);
                this.setState({ toper: response.data });
            })
            .catch((error) => {
                console.log(error);
            });
    }




    render() {

        const email = localStorage.getItem('email');
        const regid = localStorage.getItem('regid');

        // alert(regid);
        if (!email || !regid) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }


        const time_taken1 = this.state.analysis.length > 0 ? this.state.analysis[0].time - this.state.analysis[0].time_taken : 0;
        return (
            <div className='container-fluid' style={{ marginTop: '30px' }}>
                <h3 id="h3">Overall Performance Summary</h3>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className='row' id="myseries_card">
                            {/* <div className='col-sm-3' id="rank">
                                <FaAward id="rank1" />
                                {Array.isArray(this.state.rank) && this.state.rank.map((item) => (
                                    <div>{item.rank}/{item.total_students}</div>
                                ))}

                                <p id="titlep">Rank</p>
                            </div> */}
                            <div className='col-sm-3' id="rank">
                                <FaTrophy id="rank2" />
                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div>{item.total_score_obtained}/{item.total_score}</div>
                                ))}
                                <p id="titlep">Score</p>
                            </div>
                            <div className='col-sm-3' id="rank">
                                <FaFileAlt id="rank3" />
                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div>{item.total_qid}/{item.total_q}</div>
                                ))}
                                <p id="titlep">Attempted</p>
                            </div>
                            <div className='col-sm-3' id="rank">
                                <FaUsers id="rank4" />
                                {Array.isArray(this.state.rank) && this.state.rank.map((item) => (
                                    <div>{item.percentile.toFixed(2)}%</div>
                                ))}
                                <p id="titlep">Percentile</p>
                            </div>
                            <div className='col-sm-3' id="rank">
                                <FcStatistics id="rank4" />
                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div>{item.accuracy.toFixed(2)}%</div>
                                ))}

                                <p id="titlep">Accuracy</p>
                            </div>

                        </div>

                        <div className='row' id="myseries_card">
                            <div className='col-sm-3' id="rank">

                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div>{item.total_correct}/{item.total_q}</div>
                                ))}
                                <p id="titlep">Correct</p>
                            </div>
                            <div className='col-sm-3' id="rank">

                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div>{item.total_incorrect}/{item.total_q}</div>
                                ))}
                                <p id="titlep">Incorrect</p>
                            </div>
                            <div className='col-sm-3' id="rank">

                                {Array.isArray(this.state.analysis) && this.state.analysis.map((item) => (
                                    <div > ({Math.floor(time_taken1 / 60000)} /{Math.floor(item.time / 60000)})mins </div>
                                ))}
                                <p id="titlep">Time Taken </p>
                            </div>

                            <div className='col-sm-2' id="rank">

                            </div>

                        </div>
                    </div>
                    {/* <div className='col-sm-3' id="myseries_card">
                        <h6 >Top 10 Rank Holder</h6>
                        <hr></hr>
                        {Array.isArray(this.state.toper) && this.state.toper.map((item,index) => (

                            <p >{index + 1}.  <FaUserCircle id="rankh"></FaUserCircle>  <span></span>{item.email}
                                <br></br><span >Score.({item.total_score_obtained})</span><hr></hr></p>

                        ))}
                    </div> */}
                </div>


            </div>
        );
    }
}
export default HOC(Result);