import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaAngular, FaHome } from "react-icons/fa";

import logo from './img/erwatodaylogo.png';

import '../components/cssfile/header.css';
import '../components/cssfile/home.css';
import '../components/cssfile/footer.css';
import '../components/cssfile/admin.css';
import '../components/cssfile/card.css';
import '../components/cssfile/ca.css';
import '../components/cssfile/sidebar.css';
import '../components/cssfile/dashboard.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Navbar 
          collapseOnSelect 
          expand="lg" 
          bg="black" 
          variant="dark" 
          style={{ 
            boxShadow: "10px 2px 8px #9E9E9E", 
            color: "black" 
          }}
        >
          <Navbar.Brand id="title" href="/home">
          <img src={logo} style={{height:'30px',width:'50px'}}/>
            ErwaToday
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="responsive-navbar-nav" 
            id="titlebar" 
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link>
                <Link to="/">
                  <FaHome />
                </Link>
              </Nav.Link>
              <NavDropdown variant="dark" id="navld" title="Test Series">
                <NavDropdown.Item id="navl" href="/allseries">
                Series
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link 
                id="navl" 
                href="/login" 
                className='btn btn-info' 
                style={{color:'white'}}
              >
                Signin/Signup
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
