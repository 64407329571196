import React from 'react';

const Error = () =>
  <div className="error">
     <h1>Oops!! Sorry</h1><br/>
    <p>Error:404 page not found</p>
    
  </div>

export default Error;

