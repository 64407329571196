import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import moment from 'moment';


import '../components/cssfile/dashboard.css';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle, FaBars, FaCheck } from "react-icons/fa";
import { Container, Row, Col, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

class Edashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [true,], fullscreen: true, show: false, isFullScreen: false, showSidePanel: false,
      timeLeft: null,
      currentQuestionNumber: 0,

      exam_id: '',
      instruction: [],
      dcategory: [],

      time_back: [],
      markedbackup: [],
      savedbackup: [],
      visitedbackup: [],

      totalSavedQuestions: 0,

      section: [],
      question: [],

      currentQuestionIndex: null,
      answer: '',
      currentSection: null,

    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }
  ///////////////////This part for toggle system///////////////////////////////////
  handleFullScreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.setState({ isFullScreen: false });
    } else {
      document.documentElement.requestFullscreen();
      this.setState({ isFullScreen: true });
    }
  };

  toggleSidebar() {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  handleShow = (breakpoint) => {
    this.setState({
      fullscreen: breakpoint,
      show: true
    });
  }

  ///////////////This part for data loading/////////////////////////////////

  componentDidMount() {



    this.loadQuestion(this.state.currentQuestionIndex);
    ///////////this is for section and question part ///////////
    const exam_id = localStorage.getItem('exam_id');
    const param = exam_id;
    axios.get(`https://erwatoday.com/serverside/getsection.php?exam_id=${param}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ section: response.data });
        // const firstSectionId = response.data[1].sec_id; // Get the id of the first section
        // this.loadAllQuestions(); // Call the handleClick method with the first section id
      })
      .catch(error => {
        console.log(error)
      })

    const param5 = exam_id;
    axios
      .get(`https://erwatoday.com/serverside/dashboard_category.php?exam_id=${param5}`)
      .then((response) => {
        this.setState({ dcategory: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
    const regid = localStorage.getItem('regid');
    let totalTime, startTime;

    // Fetch backup_time from time_backup API
    axios
      .get(`https://erwatoday.com/serverside/time_backup.php?exam_id=${param5}&regid=${regid}`)
      .then((response) => {
        const backup_time = response.data[0].last_time;
        // alert(backup_time);
        if (backup_time) {
          // If backup_time is available, use it instead of real-time
          totalTime = moment.duration(backup_time).asMilliseconds();
          startTime =
            Date.now() -
            moment.duration(this.state.time_back.last_time).asMilliseconds();
          const time_back = response.data;
          this.setState({ time_back });

        } else {
          // If backup_time is not available, use real-time
          axios
            .get(`https://erwatoday.com/serverside/dashboard_category.php?exam_id=${param5}`)
            .then((response) => {
              const dcategory = response.data;
              const examTime = dcategory[0].time;
              totalTime = moment.duration(examTime).asMilliseconds();
              startTime = Date.now();
              this.setState({ dcategory });
            })
            .catch((error) => {
              console.log(error);
            });
        }

        this.interval = setInterval(() => {
          const timeLeft = totalTime - (Date.now() - startTime);
          if (timeLeft <= 0) {
            clearInterval(this.interval);
            this.setState({ timeLeft: 'Time Up!' });
            this.submitup(timeLeft);
          } else {
            const formattedTimeLeft = moment.utc(timeLeft).format('HH:mm:ss');
            this.setState({ timeLeft: formattedTimeLeft });
          }
        }, 1000);

        // Send data to server every 20 seconds
        setInterval(() => {
          const regid = localStorage.getItem('regid');
          const email = localStorage.getItem('email');

          const dcategory = this.state.dcategory;
          const time_back = this.state.time_back;

          let topic_name;
          if (dcategory && dcategory.length > 0 && dcategory[0].topic_name) {
            topic_name = dcategory[0].topic_name;
          } else if (time_back && time_back.length > 0 && time_back[0].topic_name) {
            topic_name = time_back[0].topic_name;
          } else {
            // Handle the case when neither dcategory nor time_back have a topic_name
          }
          const last_time1 = this.state.timeLeft;
          const [hours, minutes, seconds] = last_time1.split(':');
          const last_time = ((parseInt(hours) * 60 * 60) + (parseInt(minutes) * 60) + parseInt(seconds)) * 1000;

          axios
            .post('https://erwatoday.com/serverside/tempexam.php', {
              email,
              regid,
              exam_id,
              last_time,
              topic_name,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }, 40000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    // Clear interval to prevent memory leaks
    clearInterval(this.interval);
    localStorage.removeItem('exam_id');
    // localStorage.removeItem('secl_id');
  }


  loadQuestion = (index) => {
    const regid1 = localStorage.getItem('regid');
    const exam_id1 = localStorage.getItem('exam_id');
    axios
      .get(`https://erwatoday.com/serverside/marked_backup.php?exam_id=${exam_id1}&regid=${regid1}`)
      .then((response) => {
        // console.log(response.data)
        this.setState({ markedbackup: response.data });
      })
      .catch((error) => {
        console.log(error);
      });


    axios
      .get(`https://erwatoday.com/serverside/save_backup.php?exam_id=${exam_id1}&regid=${regid1}`)
      .then((response) => {
        console.log(response.data)
        this.setState({ savedbackup: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`https://erwatoday.com/serverside/visited_backup.php?exam_id=${exam_id1}&regid=${regid1}`)
      .then((response) => {
        console.log(response.data)
        this.setState({ visitedbackup: response.data });
      })
      .catch((error) => {
        console.log(error);
      });



    const param = localStorage.getItem('secl_id');
    // fetch a single question from the server by index
    axios
      .get(`https://erwatoday.com/serverside/getquestion.php?sec_id=${param}&index=${index}`)
      .then((response) => {
        this.setState({
          question: response.data,
          currentQuestionIndex: index,
        });
      })
      .catch((error) => {
        console.log(error);
      });

  }


  loadAllQuestions = (sec_id, section_name) => {

    const secl_id = localStorage.setItem('secl_id', sec_id);
    axios.get(`https://erwatoday.com/serverside/getquestion.php?sec_id=${sec_id}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ question: response.data, currentSection: section_name, });
        const currentSectionIndex = this.state.section.findIndex(section => section.sec_id === sec_id);
        const nextSection = this.state.section[currentSectionIndex + 1];
        if (nextSection && response.data.length === 0) {
          // If there is a next section and the current section has no questions, load the questions for the next section
          this.loadAllQuestions(nextSection.sec_id);
        } else {
          // Otherwise, the current section has questions, so load the first question
          this.loadQuestion(0);
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  deleteResponse(q_id) {
    // console.log(q_id);
    const regid = localStorage.getItem('regid');
    const exam_id = localStorage.getItem('exam_id');

    axios.delete(`https://erwatoday.com/serverside/delete_response.php?exam_id=${exam_id}&regid=${regid}&q_id=${q_id}`)
      .then(response => {
        // alert(response);
        this.loadQuestion(this.state.currentQuestionIndex);
      })
      .catch(error => {
      })
  }


  handleNextClick() {

    const regid = localStorage.getItem('regid');
    const email = localStorage.getItem('email');
    const exam_id = localStorage.getItem('exam_id');
    const dcategory = this.state.dcategory;
    const a_id = dcategory[0].a_id;
    const { question, currentQuestionIndex } = this.state;
    const currentQuestion = question && question[currentQuestionIndex];
    const q_id = currentQuestion.q_id;

    const answerElement = document.querySelector('input[name="answer"]:checked');
    let answer = answerElement ? answerElement.value : 0;

    // Prepare the data to send to the server
    const data = {
      email: email, // Replace with the user's email address
      regid: regid, // Replace with the user's registration ID
      exam_id: exam_id, // Replace with the ID of the current exam
      a_id: a_id, // Replace with the ID of the current attempt
      q_id: q_id,
      answer: answer,
    };

    // Make an API call to the server to save the selected answer
    axios.post('https://erwatoday.com/serverside/savequestion.php', data)
      .then(response => {
        // this.loadQuestion();
        console.log(response.data);
        if (answerElement) {
          answerElement.checked = false;
        }
      })
      .catch(error => {
        console.log(error);
      });


    // Make an API call to the server to save the selected answer
    axios.post('https://erwatoday.com/serverside/visitedquestion.php', data)
      .then(response => {
        console.log(response.data);
        if (answerElement) {
          answerElement.checked = false;
        }
      })
      .catch(error => {
        console.log(error);
      });

    const nextIndex = this.state.currentQuestionIndex + 1;

    if (nextIndex < this.state.question.length) {
      // if there are more questions, load the next one
      this.loadQuestion(nextIndex)
    } else {
      // if we've reached the end of the questions, disable the Next button
      const { currentQuestionIndex, section, currentQuestionNumber } = this.state;
      const currentSectionIndex = section.findIndex(sec => sec.sec_id === currentQuestionNumber.sec_id);
      // alert(currentSectionIndex);

      if (currentQuestionNumber && currentQuestionNumber.question && currentQuestionIndex < currentQuestionNumber.question.length - 1) {
        // if there are more questions in the current section, load the next one
        this.loadQuestion(currentQuestionIndex - 1);
      } else if (currentSectionIndex < section.length - 1) {
        // if we've reached the end of the current section, but there are more sections,
        // reload all the questions in the next section and update the currentQuestionNumber state
        const nextSection = section[currentSectionIndex + 1];
        this.loadAllQuestions(nextSection.sec_id, nextSection.section_name);
        this.setState({ currentQuestionNumber: nextSection, currentQuestionIndex: 0 });
      } else {
        // if we've reached the end of all the questions and sections, reload all the questions from the beginning
        this.loadAllQuestions(section[0].sec_id);
        this.setState({ currentQuestionNumber: section[0], currentQuestionIndex: 0 });
      }
    }
  }


  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }


  handleMarkQuestion() {
    // this.loadQuestion(this.state.currentQuestionIndex);
    const regid = localStorage.getItem('regid');
    const email = localStorage.getItem('email');
    const exam_id = localStorage.getItem('exam_id');
    const dcategory = this.state.dcategory;
    const a_id = dcategory[0].a_id;
    const { question, currentQuestionIndex } = this.state;
    const currentQuestion = question && question[currentQuestionIndex];
    const q_id = currentQuestion.q_id;

    const answerElement = document.querySelector('input[name="answer"]:checked');
    let answer = answerElement ? answerElement.value : 0;

    // Prepare the data to send to the server
    const data = {
      email: email, // Replace with the user's email address
      regid: regid, // Replace with the user's registration ID
      exam_id: exam_id, // Replace with the ID of the current exam
      a_id: a_id, // Replace with the ID of the current attempt
      q_id: q_id,
      answer: answer,
    };

    // Make an API call to the server to save the selected answer
    axios.post('https://erwatoday.com/serverside/markquestion.php', data)
      .then(response => {
        // this.loadQuestion(this.state.currentQuestionIndex);
        console.log(response.data);
        if (answerElement) {
          answerElement.checked = false;
        }
      })
      .catch(error => {
        console.log(error);
      });

    axios.post('https://erwatoday.com/serverside/visitedquestion.php', data)
      .then(response => {
        console.log(response.data);
        if (answerElement) {
          answerElement.checked = false;
        }
      })
      .catch(error => {
        console.log(error);
      });



    const nextIndex = this.state.currentQuestionIndex + 1;

    if (nextIndex < this.state.question.length) {
      // if there are more questions, load the next one
      this.loadQuestion(nextIndex)
    } else {
      // if we've reached the end of the questions, disable the Next button
      const { currentQuestionIndex, section, currentQuestionNumber } = this.state;
      const currentSectionIndex = section.findIndex(sec => sec.sec_id === currentQuestionNumber.sec_id);
      // alert(currentSectionIndex);

      if (currentQuestionNumber && currentQuestionNumber.question && currentQuestionIndex < currentQuestionNumber.question.length - 1) {
        // if there are more questions in the current section, load the next one
        this.loadQuestion(currentQuestionIndex - 1);
      } else if (currentSectionIndex < section.length - 1) {
        // if we've reached the end of the current section, but there are more sections,
        // reload all the questions in the next section and update the currentQuestionNumber state
        const nextSection = section[currentSectionIndex + 1];
        this.loadAllQuestions(nextSection.sec_id);
        this.setState({ currentQuestionNumber: nextSection, currentQuestionIndex: 0 });
      } else {
        // if we've reached the end of all the questions and sections, reload all the questions from the beginning
        this.loadAllQuestions(section[0].sec_id);
        this.setState({ currentQuestionNumber: section[0], currentQuestionIndex: 0 });
      }
    }
  }


  submit = (timeLeft) => {
    if (window.confirm('Are you sure you want to submit the test?')) {
      const regid = localStorage.getItem('regid');
      const email = localStorage.getItem('email');
      const exam_id = localStorage.getItem('exam_id');
      const dcategory = this.state.dcategory;
      const a_id = dcategory[0].a_id;

      const last_time1 = this.state.timeLeft;
      const [hours, minutes, seconds] = last_time1.split(':');
      const last_time = ((parseInt(hours) * 60 * 60) + (parseInt(minutes) * 60) + parseInt(seconds)) * 1000;
      const time_taken = last_time;

      const data = {
        email: email, // Replace with the user's email address
        regid: regid, // Replace with the user's registration ID
        exam_id: exam_id, // Replace with the ID of the current exam
        a_id: a_id, // Replace with the ID of the current attempt
        time_taken: last_time,
      };

      axios.post('https://erwatoday.com/serverside/submittest.php', data)
        .then(response => {
          // console.log(response.data);
          this.props.history.push('/result', { data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }


  submitup = (timeLeft) => {
    const regid = localStorage.getItem('regid');
    const email = localStorage.getItem('email');
    const exam_id = localStorage.getItem('exam_id');
    const dcategory = this.state.dcategory;
    const a_id = dcategory[0].a_id;

    const last_time1 = this.state.timeLeft;
    const [hours, minutes, seconds] = last_time1.split(':');
    const last_time = ((parseInt(hours) * 60 * 60) + (parseInt(minutes) * 60) + parseInt(seconds)) * 1000;
    const time_taken = last_time;

    const data = {
      email: email, // Replace with the user's email address
      regid: regid, // Replace with the user's registration ID
      exam_id: exam_id, // Replace with the ID of the current exam
      a_id: a_id, // Replace with the ID of the current attempt
      time_taken: last_time,
    };


    // If last_time is less than 3 seconds, submit the test automatically without confirmation
    axios.post('https://erwatoday.com/serverside/submittest.php', data)
      .then(response => {
        // console.log(response.data);
        this.props.history.push('/result', { data });
      })
      .catch(error => {
        console.log(error);
      });



  }





  render() {
    const { values, show, fullscreen } = this.state;
    const { isFullScreen } = this.state;
    const { timeLeft } = this.state;
    const { section } = this.state;

    const { question, currentQuestionIndex } = this.state;
    const currentQuestion = question && question[currentQuestionIndex];

    const email = localStorage.getItem('email');
    const regid = localStorage.getItem('regid');

    // alert(regid);
    if (!email || !regid) {
      this.props.history.push('/login'); // Redirect to login page
      return null; // Render nothing
    }

    const totalSavedQuestions = Array.isArray(this.state.savedbackup) ? this.state.savedbackup.length : 0;
    const totalMarkedQuestions = Array.isArray(this.state.markedbackup) ? this.state.markedbackup.length : 0;
    const totalVisitedQuestions = Array.isArray(this.state.visitedbackup) ? this.state.visitedbackup.length : 0;


    return (



      <Container fluid style={{ margin: 0, width: '100%' }}>
        <div id="top_header" className='row' style={{ display: 'flex', justifyContent: 'space-between', boxShadow: "1px 0px 4px #9E9E9E" }}>
          {this.state.dcategory.map((item) => (<p><span style={{ color: '#118585' }}>ErwaToday</span> {item.topic_name}</p>))}
          <div style={{ float: 'right', marginRight: '15px' }}>

            <span>
              {timeLeft && (
                <span id="timer" style={{ color: timeLeft <= "00:05:00" ? 'red' : '#118585' }}>
                  Time Left {timeLeft}
                </span>
              )}
            </span>

            {'  '}

            <button onClick={this.handleFullScreenToggle} className="btn btn-outline-primary" style={{ height: '35px', paddingLeft: '5px' }}>
              {this.state.isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
            </button>{' '}
            <Link to={{ pathname: `/dashboard`, }} >
              <button className="btn btn-outline-primary" style={{ height: '35px', padding: '3px' }} >Pause</button>
            </Link>

            <span className="d-block d-sm-none"
              style={{}}>
              {values.map((v, idx) => (
                <Button
                  key={idx}
                  className="me-2 mb-2"
                  style={{ marginTop: '2px', borderRadius: '50px' }}
                  onClick={() => this.handleShow(v)}
                >
                  <FaBars />
                  {typeof v === 'string' && `below ${v.split('-')[0]}`}
                </Button>
              ))}
            </span>

          </div>

        </div>


        <Row style={{ display: 'flex', justifyContent: 'space-between', margin: '0px' }}>

          <Col sm={9} style={{ overflowY: 'scroll', height: '530px' }}>
            <div id="top_header1" className='row'
              style={{ overflowY: 'scroll', height: '35px' }}>
              Section | {Array.isArray(section) && section.map((item) => (
                <div >
                  <Link className='btn btn-outline-secondary' onClick={() => this.loadAllQuestions(item.sec_id, item.section_name)} id="section_bar" >
                    {item.section_name}
                  </Link>
                </div>
              ))}

            </div>
            <span style={{ float: 'right', marginTop: '5px' }}>View In:
              <select>
                <option value="">...select...</option>
                <option value="hi">Hindi</option>
                <option value="en">English</option>
              </select>
            </span>
            <div className='row' id="question_d">
              <div >
                {currentQuestion ? (
                  <div>
                    <p style={{ fontWeight: '800' }}>Question No.{currentQuestionIndex + 1}</p>

                    <p>{ReactHtmlParser(currentQuestion.question)}</p>
                    <div style={{}}>
                      <input type="radio" name="answer" value={currentQuestion.option1} id="rad" onChange={this.handleChange} />
                      <label htmlFor="option1" id="rad" className={
                        this.state.savedbackup && Array.isArray(this.state.savedbackup) &&
                          this.state.savedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option1) ?
                          'matched-answer' :
                          (this.state.markedbackup && Array.isArray(this.state.markedbackup) &&
                            this.state.markedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option1) ?
                            'marked-answer' : '')
                      }>{ReactHtmlParser(currentQuestion.option1)}</label>

                      <br />
                      <input type="radio" name="answer" value={currentQuestion.option2} id="rad" onChange={this.handleChange} />
                      <label htmlFor="option2" id="rad" className={
                        this.state.savedbackup && Array.isArray(this.state.savedbackup) &&
                          this.state.savedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option2) ?
                          'matched-answer' :
                          (this.state.markedbackup && Array.isArray(this.state.markedbackup) &&
                            this.state.markedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option2) ?
                            'marked-answer' : '')
                      }>{ReactHtmlParser(currentQuestion.option2)}</label>
                      <br />
                      <input type="radio" name="answer" value={currentQuestion.option3} id="rad" onChange={this.handleChange} />
                      <label htmlFor="option3" id="rad" className={
                        this.state.savedbackup && Array.isArray(this.state.savedbackup) &&
                          this.state.savedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option3) ?
                          'matched-answer' :
                          (this.state.markedbackup && Array.isArray(this.state.markedbackup) &&
                            this.state.markedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option3) ?
                            'marked-answer' : '')
                      }>{ReactHtmlParser(currentQuestion.option3)}</label>

                      <br />
                      <input type="radio" name="answer" value={currentQuestion.option4} id="rad" onChange={this.handleChange} />
                      <label htmlFor="option4" id="rad" className={
                        this.state.savedbackup && Array.isArray(this.state.savedbackup) &&
                          this.state.savedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option4) ?
                          'matched-answer' :
                          (this.state.markedbackup && Array.isArray(this.state.markedbackup) &&
                            this.state.markedbackup.some(item => item.q_id === currentQuestion.q_id && item.answer === currentQuestion.option4) ?
                            'marked-answer' : '')
                      }>{ReactHtmlParser(currentQuestion.option4)}</label>

                      <br />
                    </div>
                    <div style={{ position: 'fixed', bottom: 0, marginBottom: '5px', width: '100%' }}>
                      <hr></hr>
                      <button className='btn btn-primary' onClick={() => this.handleMarkQuestion()}
                        style={{ marginLeft: '0px' }}>Mark for Review & Next</button>
                      <button className='btn btn-primary' onClick={() => this.deleteResponse(currentQuestion.q_id)}
                        style={{ marginLeft: '5px' }}>Clear Response</button>
                      <button className='btn btn-primary' onClick={() => this.handleNextClick()}
                        style={{ marginLeft: '5px' }}>Next & Save</button>

                    </div>
                  </div>
                ) : (
                  <h5 id="h3">Click on Any Section ,<br></br>to start the test & show questions !!</h5>
                )}

              </div>


            </div>

          </Col>

          <Col col={3} className={this.state.showSidebar ? "d-block" : "d-none d-sm-block"} id="myseries_carde"
            style={{ marginLeft: '0px', marginRight: '0px', paddingRight: '0px' }}>
            <div style={{ marginTop: '0PX' }}>
              <FaUserCircle style={{ fontSize: '30px' }} />    {email}
              <hr></hr>
              <div className='row' style={{ marginLeft: '10px' }}>
                <p><button id="button_plate" style={{ backgroundColor: 'green' }}>{totalSavedQuestions}</button>  Answered
                  {'  '}<button id="button_plate" style={{ backgroundColor: 'blue' }}>{totalMarkedQuestions}</button>  Marked
                  {'  '}<button id="button_plate" style={{ backgroundColor: 'white' }}>..</button> Not visited </p>
                <p>{'  '}<button id="button_plate" style={{ backgroundColor: 'red' }}>..</button>  Not Answered</p>
              </div>
              <hr></hr>
              <h6 id="section">Section: {this.state.currentSection}</h6>
              <div className='container' style={{ height: '270px', overflow: 'auto', overflowX: 'hidden' }}>
              <div className='row'    >

                {Array.isArray(this.state.question) && this.state.question.map((item, index) => {
                  const isMarked = Array.isArray(this.state.markedbackup) && this.state.markedbackup.some((markedItem) => markedItem.q_id.toString() === item.q_id.toString());
                  const isSaved = Array.isArray(this.state.savedbackup) && this.state.savedbackup.some((savedItem) => savedItem.q_id.toString() === item.q_id.toString());
                  const isVisited = Array.isArray(this.state.visitedbackup) && this.state.visitedbackup.some((visitedItem) => visitedItem.q_id.toString() === item.q_id.toString());
                  return (
                    <div key={item.q_id}>
                      <button
                        id="button_plate"
                        onClick={() => this.loadQuestion(index)}
                        style={{
                          backgroundColor: isSaved ? 'green' : isMarked ? 'blue' : isVisited ? 'red' : 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '35px',
                          height: '35px',
                          marginLeft: '8px',
                        }}
                      >
                        {index + 1}
                      </button>
                    </div>
                  )
                })}

              </div>
              </div>
             

              <button className='btn btn-primary' onClick={() => this.submit()}
                style={{ position: 'fixed', bottom: 0, marginBottom: '5px', }} id="submit_button">Submit Test</button>

              {show && (
                <Modal show={show} fullscreen={fullscreen} onHide={() => this.setState({ show: false })}>
                  <Modal.Header closeButton>
                    <Modal.Title>Question Plate</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ marginTop: '30PX', display: 'flex', }}>
                      <FaUserCircle style={{ fontSize: '30px' }} />{email}<br></br>
                      <hr></hr>
                      <div className='container' style={{ height: '270px', overflow: 'auto', overflowX: 'hidden' }}>
              <div
                className='row'
               
              >
         <br></br>
                {Array.isArray(this.state.question) && this.state.question.map((item, index) => {
                  const isMarked = Array.isArray(this.state.markedbackup) && this.state.markedbackup.some((markedItem) => markedItem.q_id.toString() === item.q_id.toString());
                  const isSaved = Array.isArray(this.state.savedbackup) && this.state.savedbackup.some((savedItem) => savedItem.q_id.toString() === item.q_id.toString());
                  const isVisited = Array.isArray(this.state.visitedbackup) && this.state.visitedbackup.some((visitedItem) => visitedItem.q_id.toString() === item.q_id.toString());
                  return (
                    <div key={item.q_id}>
                      <button
                        id="button_plate"
                        onClick={() => this.loadQuestion(index)}
                        style={{
                          backgroundColor: isSaved ? 'green' : isMarked ? 'blue' : isVisited ? 'red' : 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '35px',
                          height: '35px',
                          marginLeft: '8px',
                        }}
                      >
                        {index + 1}
                      </button>
                    </div>
                  )
                })}

                <button className='btn btn-primary' onClick={() => this.submit()}
                style={{ position: 'fixed', bottom: 0, marginBottom: '5px', }} id="submit_button">Submit Test</button>

              </div>
              </div>
             

                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </Col>


        </Row>

      </Container>


    );
  }
}

export default Edashboard;