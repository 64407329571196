import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Myseries extends React.Component {
  constructor() {
    super();
    this.state = {
      category: [],
      paymentpr: [],
      isLoading: true,
      submit: [],
      message:[],



    }
  }

  componentDidMount() {


    // localStorage.removeItem('exam_id');
    // localStorage.removeItem('secl_id');

    const a_id = this.props.location.state?.a_id;
    if (!a_id) {
      // Redirect to login page or show an error message
      this.props.history.push('/login');
      return;
    }

    axios
    .get(`https://erwatoday.com/serverside/message.php`)
    .then(response => {
      this.setState({ message: response.data });
    })
    .catch(error => {
      console.log(error);
    });

    axios.get(`https://erwatoday.com/serverside/submittest_protection.php`)
  .then((response) => {
    if (Array.isArray(response.data)) {
      this.setState({ submit: response.data });
    } else {
      console.log('Error: server response is not an array');
    }
  })
  .catch((error) => {
    console.log(error);
  });



    axios
      .get(`https://erwatoday.com/serverside/category.php?a_id=${a_id}`)
      .then(response => {
        this.setState({ category: response.data });
        const test_id = response.data[0].a_id;
        localStorage.setItem('test_id', test_id);
      })
      .catch(error => {
        console.log(error);
      });

    /////////////////////////////////Payment protection//////////////////////////
    const regid = localStorage.getItem('regid');
    const param4 = regid;
    axios.get(`https://erwatoday.com/serverside/payment_protection.php?regid=${param4}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ paymentpr: response.data, isLoading: false });
        const payment_regid = response.data[0].regid;
        localStorage.setItem('payment_regid', payment_regid);

        const payment_exp = response.data[0].enrolment_ex_date;
        localStorage.setItem('payment_exp', payment_exp);
        this.getpaymentData(payment_regid);
      })
      .catch(error => {
        console.log(error)
      })

  }

  getpaymentData(payment_regid) {
    const param4 = payment_regid;
    axios
      .get(`https://erwatoday.com/serverside/payment_protection.php?regid=${param4}`)
      .then(response => {
        this.setState({ paymentpr: response.data, isLoading: false });
        localStorage.setItem('series', response.data);
      })
      .catch(error => {
        console.log(error);
      });

  }


  render() {
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    const regid = localStorage.getItem('regid');

    const payment_regid = localStorage.getItem('payment_regid');
    const payment_exp = localStorage.getItem('payment_exp')
    const test_id = localStorage.getItem('test_id');
    const sub_id = localStorage.getItem('sub_id');


    // alert(test_id);
    // alert(regid);
    if (!email || !password || !this.props.location.state?.topic_name) {
      this.props.history.push('/login'); // Redirect to login page
      return null; // Render nothing
    }

    const submit = this.state.submit;

    const currentDate = new Date();
    const enrollmentExDate = new Date(payment_exp);


    return (
      <div className=''>
        <h3 id="h3" >{this.props.location.state.topic_name} </h3>
        <hr></hr>
        <div className='row'>
        <div className='col-sm-9' >
          {this.state.category.map((item) => (

            <div id="myseries_card" className={item.pnp == 0 || payment_regid == undefined || regid == payment_regid && currentDate <= enrollmentExDate ? "green-border" : "red-border"}>
              <p id="title">ST:{item.topic_name}</p>
              <span className="label label-primary" id="title1">{item.total_q} Questions </span>
              <span class="label label-primary" id="title1"> {item.max_marks*item.total_q} Marks  </span>
              <span class="label label-primary" id="title1">{Math.floor(item.time / 60000)} mins</span>
              <span class="label label-primary" id="titlep"> {item.n_marks} Negative Marks</span>


              {item.pnp == 0 ? (
                submit.find(items => items.regid === regid && items.exam_id === item.id) ? (
                  <div>

                  <Link
                        to={{
                          pathname: `/solution`,
                          state: { exam_id: item.id, regid: regid, email: email, topic_name: item.topic_name },
                        }}
                      >
                        <button className="btn btn-primary" style={{ float: "right",marginLeft:'5px' }}>
                          View Solution
                        </button>
                      </Link>{'   '}
               
                  <Link
                    to={{
                      pathname: `/result`,
                      state: { id: item.id,  regid: regid },
                    }}
                  >
                    <button className="btn btn-primary" style={{ float: "right" }}>
                      View Analysis
                    </button>
                  </Link>
                  </div>
                ) : (
                  <Link
                    to={{
                      pathname: `/instruction`,
                      state: { id: item.id, test_id: test_id, regid: regid, email: email, topic_name: item.topic_name },
                    }}
                  >
                    <button className="btn btn-primary" style={{ float: "right" }}>
                      Start Test
                    </button>
                  </Link>
                )
              ) : (
                item.status == 1 ? (

                  payment_regid == undefined || regid == payment_regid && currentDate <= enrollmentExDate ? (

                    submit.find(items => items.regid === regid && items.exam_id === item.id) ? (
                      <div>
                      <Link
                        to={{
                          pathname: `/solution`,
                          state: { exam_id: item.id, regid: regid, email: email, topic_name: item.topic_name },
                        }}
                      >
                        <button className="btn btn-primary" style={{ float: "right" ,marginLeft:'5px'}}>
                          View Solution
                        </button>
                      </Link>
                      <Link
                        to={{
                          pathname: `/result`,
                          state: { id: item.id, test_id: test_id, regid: regid, email: email, topic_name: item.topic_name },
                        }}
                      >
                        <button className="btn btn-primary" style={{ float: "right" }}>
                          View Analysis
                        </button>
                      </Link>
                      </div>
                    ) : (
                      <Link
                        to={{
                          pathname: `/instruction`,
                          state: { id: item.id, test_id: test_id, regid: regid, email: email, topic_name: item.topic_name },
                        }}
                      >
                        <button className="btn btn-primary" style={{ float: "right" }}>
                          Start Test
                        </button>
                      </Link>
                    )

                  ) : (

                    <Link to={{ pathname: `/payment`, state: { regid: regid, email: email } }}>
                      <button className='btn btn-primary' style={{ float: 'right' }}>Unlocked</button>
                    </Link>
                  )
                ) : (
                  <button className='btn btn-danger' style={{ float: 'right' }}>Not Activated</button>
                )
              )}
           <br></br>  <br></br>   
            </div>

          ))}
        </div>
        <div className='col-sm-2' id="panel">
          <h6>New Added Test Series</h6>
          <hr></hr>
          {this.state.message.slice(0, 5).map((item) => (
              <div id="panel">
                <p>{item.name}</p>
                <div>{ReactHtmlParser(item.des)}</div>
              </div>
            ))}
        </div>

        </div>
       
      </div>
    );
  }



}
export default HOC(Myseries);