import React from 'react';
import { Navbar, Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';

class ContactUs extends React.Component {
  render() {
    return (
      <div id="contactus">
        <Header />
        <Navbar expand="lg">
          <Container>
            <Row>
              <Col lg={12}>
                <h1>Contact Us</h1>
                <hr />
               
                ErwaToday.Com<br></br>
                91/C Jai Mata Di Apartment Opposite of Global mobile World Shop
                <br></br>
                B.L SAHA ROAD TOLLYGUNJ, KOLKATA WEST BENGAL -700053
                Pincode:- 700053
                <br></br>
                Email:- sikandar0503@gmail<br></br>
                Phone:- 9608372362

                <p>Feel free to contact us with any questions, concerns or feedback you may have. We'd love to hear from you!</p>
            
              </Col>
            </Row>
          </Container>
        </Navbar>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <Form>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" required />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" required />
                </Form.Group>

                <Form.Group controlId="formBasicMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} placeholder="Enter your message" required />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactUs;
