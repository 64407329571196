import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import '../components/cssfile/Appp.css';
import { Navbar, Nav, NavDropdown, Label } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default class Ca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      a_id: [],
      question: [],
      id: [],
      topic_name: [],
      currentPage: 1,

      
    };

  }


  getData = () => {
    axios.get(`http://localhost/currentaffairs/serverside/category.php?a_id=${this.props.location.state.a_id}&page=${this.state.currentPage}`).then(response => {
      this.setState({ category: response.data });
    });
  }


hanldleClick=(e)=>{
  
  axios.get('http://localhost/currentaffairs/serverside/question.php?id=' + this.props.location.state.id).then(response => {
    this.setState({ question: response.data });
  });

}

  handleNextClick = () => {
    const { currentPage } = this.state;
    this.getData(currentPage + 1);
    this.setState({ currentPage: currentPage + 1 });
    console.log(this.state);
  }
  handlePrevClick = () => {
    const { currentPage } = this.state;
    this.getData(currentPage - 1);
    this.setState({ currentPage: currentPage - 1 });
    console.log(this.state);
  }


  componentDidMount() {
    this.getData(this.state.currentPage);
    // axios.get('http://localhost/currentaffairs/serverside/category.php?a_id=' + this.props.location.state.a_id).then(response => {
    //   this.setState({ category: response.data });
    // });
    axios.get('http://localhost/currentaffairs/serverside/question.php?id=' + this.props.location.state.id).then(response => {
      this.setState({ question: response.data });
    });
  }


  render() {
    const { question } = this.state;
    const { category, currentPage } = this.state;
    return (
      <div>
        <div className="row no-gutters" id="row" >

          <div className='col-sm-2' id="panel">
            <h5 id="h3">Browse...</h5>
            {category.length > 0 ? category.map((item) => (
              <div id="linkh">
                <Link onClick={this.hanldleClick} id="linkh" to={{ pathname: `${item.url}`, state: { id: item.id,topic_name:item.topic_name } }}>
                  <i class="fas fa-external-link-alt" ></i> | {item.topic_name}
                </Link>
              </div>
            )): <div id="nodata">Sorry!!<br></br>No Data available</div>}
          </div>



          <div className='col-sm-7' id="panel">
            <h5 id="h3">{this.props.location.state.topic_name}</h5>
            <button id="buttoncs" className='btn btn-outline-info'>Next</button>
            <button id="buttoncs" style={{ float: 'right' }} className='btn btn-outline-info'>Previous</button>
            <hr id="hr" />
            {question.length > 0 ? question.map((item) => (
              <div>
                <label>{ReactHtmlParser(item.des)}{ReactHtmlParser(item.detail)}</label>
              </div>
            )) : <div id="nodata">Sorry!!<br></br>No Data available</div>}

            
            <button id="buttoncs" className='btn btn-outline-info' onClick={this.handleNextClick} >Next</button>
            <button id="buttoncs" style={{ float: 'right' }} className='btn btn-outline-info' disabled={currentPage === 1} onClick={this.handlePrevClick}>Previous</button>
          </div>




          <div className='col-sm-2' id="panel">
            <h4 >ADDS....</h4>

            <hr />
          </div>
        </div>
      </div>

    )
  }
}



