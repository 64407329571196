import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import '../components/cssfile/Appp.css';

export default class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category:[],
            a_id:[],
            topic_name:[],

            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0
        };
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }
    receivedData() {
        axios
        .get('http://localhost/erwatoday/serverside/category.php?a_id='+ this.props.location.state.a_id)
            .then(res => {

                const data = res.data;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)

                const postData = slice.length > 0 ? slice.map(pd => <React.Fragment>
                    <Link id="linkh" to={{pathname:`/ca/${pd.url}`, state: { id: pd.id,a_id:pd.a_id,topic_name:pd.topic_name } }}>
                        <i class="fas fa-external-link-alt" ></i> | {pd.topic_name} 
                    </Link>
                </React.Fragment> ): <div id="nodata">Sorry!!<br></br>No Data available</div>

                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),

                    postData
                })
            });
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        this.receivedData()
    }
    render() {
        return (
            <div>
               
                    <div className='row' id="row">
                    <div className='col-sm-2' ></div>
                    <div className='col-sm-7' id="panel">
                    <h1 id="h3" >{this.props.location.state.topic_name} </h1>
                    <br></br>
                    <ul class="list-group"  >
                        {this.state.postData }
                    </ul>
                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div>
                <div className='col-sm-2'></div>
                </div>
            </div>

        )
    }
}



