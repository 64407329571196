import React from 'react';
import axios from 'axios';
import Questiond from './getquestiond';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt, FaUserCircle, FaBars } from "react-icons/fa";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [true,], fullscreen: true, show: false, isFullScreen: false,
      timeLeft: null,

      question: [],
      section: [],
      exam_id: '',
      
    };
  }

  componentDidMount() {
    const exam_id = this.props.exam_id;
    const param = exam_id;
    axios.get(`http://localhost/erwatoday/serverside/getsection.php?exam_id=${param}`)
      .then(response => {
        console.log(response.data);
        this.setState({ section: response.data });
        const firstSectionId = response.data[0].sec_id; // Get the id of the first section
        this.handleClick(firstSectionId); // Call the handleClick method with the first section id
      })
      .catch(error => {
        console.log(error)
      })
  }
  

  handleClick = (sec_id) => {
    alert('hello');
    const param = sec_id;
    axios.get(`http://localhost/erwatoday/serverside/getquestion.php?sec_id=${param}`)
      .then(response => {
        console.log(response.data);
        this.setState({
          question: response.data,
        });
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { section} = this.state;

    return (
      <div className='row'>

      
        Section | {section.map((item) => (
          <div className='col-sm-3' >
            <button
              
              onClick={() => this.handleClick(item.sec_id)}
            >
              {item.section_name}{item.sec_id}
            </button>
          </div>
        ))}
     
        <div className='row' style={{marginTop:'300px',color:'black'}}>
       

        {Array.isArray(this.state.question) && this.state.question.map((item)=>(
          <div key={item.q_id}>{item.question}</div>  
        ))}
      </div>
      
      </div>
    );
  }
}
