import React from 'react';
import axios from 'axios';
import HOC from './HOC';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';
import { FaAngular, FaSignOutAlt } from "react-icons/fa";
import { Navbar, Nav, Card, Title, Text } from 'react-bootstrap';



import { Container, Row, FormGroup, FormLabel, FormControl, } from 'react-bootstrap';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            series: [],
            isLoading: true,

        }

    }


    componentDidMount() {

        // localStorage.removeItem('exam_id');
        // localStorage.removeItem('secl_id');

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const regid = localStorage.getItem('regid');
        localStorage.getItem('series');

        const param1 = email;
        const param2 = password;
        axios
            .get(`https://erwatoday.com/serverside/userdata.php?email=${param1}&password=${param2}`)
            .then(response => {
                this.setState({ user: response.data });
                const regid = response.data[0].regid;
                localStorage.setItem('regid', regid);
                this.getSeriesData(regid);
            })
            .catch(error => {
                console.log(error);
            });

        this.setState({ email, password });
    }

    getSeriesData(regid) {
        const param3 = regid;
        axios
            .get(`https://erwatoday.com/serverside/seriesdata.php?regid=${param3}`)
            .then(response => {
                this.setState({ series: response.data, isLoading: false });
                localStorage.setItem('series', response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }


    handleLogout = () => {

        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('regid');
        this.props.history.push('/login');
    }

    render() {
        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        if (!email || !password) {
            this.props.history.push('/login'); // Redirect to login page
            return null; // Render nothing
        }

        return (
            <div>
                <div className='container' id="dashboard">
                    {/* <button onClick={this.handleLogout} style={{ float: 'right' }}><FaSignOutAlt />{email}</button> */}
                    <h3>Your Recent Test Series</h3>
                    <div className='row' >
                        {this.state.series.length > 0 ? this.state.series.map((item) => (
                            <div className='col-sm-3'>
                                <div id="card">
                                    <img src={item.image} width="50px" height="50px" id="dashboard_img" />
                                    <br></br>
                                    <p>{item.topic_name}</p>
                                    <hr></hr>
                                    <p id="cardp">1.{item.total_set}<br></br>
                                        2.{item.previous_year}</p>
                                    <p>And many more....</p>
                                    <Link to={{ pathname: `${item.url}`, state: { a_id: item.a_id, topic_name: item.topic_name } }}>
                                        <button id="button" className='btn btn-outline-info' >Get Started Now</button>
                                    </Link>
                                </div>

                            </div>

                        )) : <div id="nodata">Sorry!!<br></br>No Any Series is Added !<br></br> Please Add series!
                        <br></br>
                        <Link to={{pathname:'/home'}} className='btn btn-info'>Add Series</Link>
                        
                        </div>
                        }

                    </div>
                </div>
            </div>

        );
    }
}
export default HOC(Dashboard);