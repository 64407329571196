import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';

class TermsAndConditions extends React.Component {
render() {
return (

<div id="termsandconditions">
<Header />
<Navbar expand="lg">
<Container>
<Row>
<Col lg={12}>
<h1>Terms and Conditions</h1>
<hr />
<p>By accessing and using this website, you accept and agree to be bound by the following terms and conditions:</p>
<h3>1. Intellectual Property Rights</h3>
<p>The content and materials on this website, including but not limited to text, graphics, logos, images, and software, are the property of ErwaToday.in and are protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, distribute, modify, or create derivative works from any of the content or materials on this website without our prior written consent.</p>
<h3>2. Use of the Website</h3>
<p>You may use this website only for lawful purposes and in accordance with these terms and conditions. You may not use this website in any way that could damage, disable, overburden, or impair our servers or networks or interfere with any other party's use and enjoyment of this website.</p>
<h3>3. User Content</h3>
<p>Any content that you submit to us through this website, including but not limited to comments, feedback, and suggestions, will be considered non-confidential and non-proprietary. By submitting such content, you grant us a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such content in any form, media, or technology now known or later developed.</p>
<h3>4. Disclaimer of Warranties</h3>
<p>This website is provided "as is" and "as available" without any representations or warranties, express or implied. We make no representations or warranties in relation to this website or the information and materials provided on this website.</p>
<h3>5. Limitation of Liability</h3>
<p>We will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of this website or the information and materials provided on this website.</p>
<h3>6. Indemnification</h3>
<p>You agree to indemnify and hold us and our affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these terms and conditions or your violation of any law or the rights of a third party.</p>
<h3>7. Governing Law</h3>
<p>These terms and conditions shall be governed by and construed in accordance with the laws of India.</p>
<h3>8. Changes to These Terms and Conditions</h3>
<p>We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of this website after any such changes constitutes your acceptance of the new terms and conditions.</p>
<h3>Contact Us</h3>
<p>If you have any questions or concerns about these terms and conditions, please contact us at privacy@erwatoday.com.</p>
<h3>Payment Related Terms & Condition</h3>
<div>
Validity of Online Mock Test Series: The validity of the online mock test series offered on this website will be one year from the date of enrollment. After one year, access to the mock tests will be terminated, and no refund or extension will be provided.

Validity of Payment: The validity of payment made for the online mock test series will also be one year from the date of enrollment. After one year, the payment will be considered expired, and no refund or extension will be provided.

Test Series Content: The online mock test series content and materials, including but not limited to the questions, answers, explanations, and solutions, are the property of ErwaToday.in and are protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, distribute, modify, or create derivative works from any of the test series content or materials without our prior written consent.

Test Series Conduct: The online mock test series is conducted for educational and testing purposes only. Any attempt to cheat or engage in any fraudulent activity will result in immediate termination of access to the test series and may result in legal action.

Technical Issues: We strive to provide a smooth and uninterrupted online test experience, but we cannot guarantee the absence of technical issues, including but not limited to server downtime, internet connectivity issues, or software errors. We will not be held liable for any loss or damage caused by such technical issues.

Refund Policy: We do not offer refunds for the online mock test series, except in cases of technical issues that prevent access to the tests. In such cases, we will provide a full refund upon verification of the issue.

Modifications to Test Series: We reserve the right to modify the online mock test series content, format, and duration at any time without prior notice. Your continued use of the test series after any such modifications constitutes your acceptance of the changes.

Termination of Access: We reserve the right to terminate your access to the online mock test series at any time, without prior notice or refund, if you breach these terms and conditions or engage in any fraudulent activity.

By accessing and using the online mock test series on this website, you accept and agree to be bound by these additional terms and conditions.
</div>
</Col>
</Row>
</Container>
</Navbar>
<Footer />
</div>
);
}
}
export default TermsAndConditions;